<template>
    <div align="center">
        <br><br>

        <div class="row justify-center">
            <div class="col-12 col-md-10">
                <QQButton label="Cerca Cliente" color="blue-grey" icon="mdi-clipboard-check-multiple-outline" size="xl" @click.native="onCercaCliente()"/>
                <QQButton label="Cerca Clienti per Cellulare" color="blue-grey" icon="mdi-note-search-outline" size="xl" @click.native="onCercaPerCellulare()"/>
            </div>

            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    import {mapFields} from "vuex-map-fields"
    import { mapState, mapActions } from "vuex";

    export default {
        name: "MenuClienti",
        data() {
            return {
                
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestionePratiche", {

            }),
            ...mapState({
                // formPratica: state => state.gestionePratiche.formPratica
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            ...mapActions({
            //    fetchDatiCliente: "gestionePratiche/fetchDatiClientePratica",
            //    setAnnullaPratica: "gestionePratiche/setAnnullaPratica",
            //    fetchNotePratica: "gestionePratiche/fetchNotePratica",
            //    fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
            //    clearElencoDocumentiPratica: "gestioneDocumenti/clearElencoDocumentiPratica"
            }),
            onCercaCliente() {
                this.$router.push({name : "Clienti.CercaCliente"});
            },
            onCercaPerCellulare() {
                this.$router.push({name : "Clienti.CercaCellulare"});
            },
            
        },
        async mounted() {
        },
        async activated() {
        }
    }
</script>
